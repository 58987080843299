import React from "react"

import { graphql } from "gatsby"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import colors from "../styles/colors"
import dimensions from "../styles/dimensions"

import ProjectCard from "../components/_ui/ProjectCard"

const WorkGrid = styled("div")`
  display: grid;
  grid-gap: 30px;

  grid-template-columns: 1fr 1fr;
  @media (max-width: ${dimensions.maxwidthMobile}px) {
    grid-template-columns: 1fr;
  }
`
const WorkPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Work" />
      <div className="content title">
        <h1>Work</h1>
      </div>
      <WorkGrid className="content">
        {posts.map((post, i) => {
          return (
            <ProjectCard
              thumbnail={post.frontmatter.thumbnail}
              key={i}
              title={post.frontmatter.title}
              date={post.frontmatter.date}
              description={post.frontmatter.description}
              tags={post.frontmatter.tags}
              link={post.fields.slug}
            />
          )
        })}
      </WorkGrid>
    </Layout>
  )
}

export default WorkPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          tags
          description
          thumbnail {
            childImageSharp {
              sizes(maxWidth: 1000) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
      }
    }
  }
`
